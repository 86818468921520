<template>
  <div class="boxWrap flexB list">
    <div v-for="(data, i) in postList" :key="i" class="box card">
      <router-link to @click.native="handleMove(data._id)">
        <div class="point">{{ data.point }}P</div>
        <div class="img">
          <img :src="`${data.thumbnail}`" alt="" />
        </div>
        <div class="tit">
          <span>
            {{
              data.category == "SPECIAL"
                ? "스페셜"
                : data.category == "TECH"
                ? "테크/가전"
                : data.category == "FASHION"
                ? "패션/잡화"
                : data.category == "BEAUTY"
                ? "뷰티"
                : data.category == "FOOD"
                ? "푸드"
                : data.category == "HOMELIVING"
                ? "홈리빙"
                : data.category == "TRIP"
                ? "여행/레저"
                : data.category == "SPORTS"
                ? "스포츠/모빌리티"
                : data.category == "CULTURE"
                ? "공연/컬쳐"
                : data.category == "KIDS"
                ? "베이비/키즈"
                : ""
            }}</span
          >
          <h4>{{ data.title }}</h4>
        </div>
        <div class="price">
          <div class="flexB">
            <em>시작가</em>
            <span>{{ numberFormat(data.startAmount) }}원</span>
          </div>
          <div class="flexB">
            <em class="primary">현재가</em>
            <span class="primary">{{ numberFormat(Math.round(data.avgAmount)) }}원</span>
          </div>
          <div class="line"></div>
        </div>
        <div class="live">
          <span>{{ data.surveyers.length }} 명</span>
          <span v-if="data.limitType == 'PERIOD'">{{ moment(data.endAt).fromNow() }} 마감</span>
          <span v-else-if="data.limitType == 'COUNT'">{{ data.userCount }}명</span>
        </div>
        <div class="coupon">
          <p>* {{ data.couponInfo }}</p>
        </div>
      </router-link>
    </div>
    
  </div>
</template>
<script>
import "moment/locale/ko";
import moment from "moment";
import { mapState } from "vuex";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { format } from "@/mixins/format";

export default {
  mixins: [format],
  name: "swiper-example-loop",
  title: "Loop mode / Infinite loop",
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    postList: [],
  },
  data() {
    return {
      moment: moment,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        // loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState(["userId"]),
  },
  methods: {
    handleMove(id) {
      this.$router.push({ name: "surveyDetail", params: { id: id } });
    },
  },
};
</script>
