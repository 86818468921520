<template>
  <div class="container funding">
    <div class="base">
      <section class="contents banner">
        <article class="inner">
          <swiper class="swiper pc" :options="swiperOption">
            <swiper-slide class="slide1">
              <div class="left">
                <router-link
                  to="/survey/detail/623827ed176dfc6061594032"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/62382995176dfc6061594033"
                ></router-link>
              </div>
            </swiper-slide>
            <swiper-slide class="slide2">
              <div class="center">
                <router-link
                  to="/survey/detail/6235451358da882b842a5a22"
                ></router-link>
              </div>
            </swiper-slide>
            <swiper-slide class="slide3"
              ><div class="left">
                <router-link
                  to="/survey/detail/6234865a58da882b842a5a1d"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/624adfe4444c4838ee44686a"
                ></router-link></div
            ></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <swiper class="swiper mobile" :options="swiperOption2">
            <swiper-slide class="slide1"
              ><div class="left">
                <router-link
                  to="/survey/detail/623827ed176dfc6061594032"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/62382995176dfc6061594033"
                ></router-link></div
            ></swiper-slide>
            <swiper-slide class="slide2"
              ><div class="center">
                <router-link
                  to="/survey/detail/6235451358da882b842a5a22"
                ></router-link></div
            ></swiper-slide>
            <swiper-slide class="slide3"
              ><div class="left">
                <router-link
                  to="/survey/detail/6234865a58da882b842a5a1d"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/624adfe4444c4838ee44686a"
                ></router-link></div
            ></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </article>
      </section>

      <section class="contents">
        <article class="inner">
          <div class="title flexB">
            <h3>
              {{
                category == "SPECIAL"
                  ? "스페셜"
                  : category == "TECH"
                  ? "테크/가전"
                  : category == "FASHION"
                  ? "패션/잡화"
                  : category == "BEAUTY"
                  ? "뷰티"
                  : category == "FOOD"
                  ? "푸드"
                  : category == "HOMELIVING"
                  ? "홈리빙"
                  : category == "TRIP"
                  ? "여행/레저"
                  : category == "SPORTS"
                  ? "스포츠/모빌리티"
                  : category == "CULTURE"
                  ? "공연/컬쳐"
                  : category == "KIDS"
                  ? "베이비/키즈"
                  : ""
              }}
            </h3>
            <div class="searchbox flex">
              <input type="text" v-model="searchWord" @keyup.enter="search" />
              <button @click="search">
                <span class="material-icons"> search </span>
              </button>
            </div>
          </div>
          <CategoryList :postList="postList" />
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostList } from "@/api/post";
import { fetchBanners } from "@/api/index";
import CategoryList from "@/components/contents/CategoryList";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-loop",
  title: "Loop mode / Infinite loop",
  components: {
    Swiper,
    SwiperSlide,
    CategoryList,
  },

  data() {
    return {
      moment: moment,
      startYmd: "",
      postList: [],
      images: [],
      category: "",
      searchWord: "",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 7000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 7000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // breakpoints: {
        //   0: {
        //     slidesPerView: 1.1,
        //     spaceBetween: 20,
        //   },
        //   500: {
        //     slidesPerView: 1.4,
        //   },
        //   768: {
        //     slidesPerView: 2.2,
        //   },
        //   1024: {
        //     slidesPerView: 2.2,
        //   },
        // },
      },
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 8);
  },
  computed: {
    ...mapState(["userId"]),
  },
  mounted() {
    if (this.$route.params.category) {
      this.category = this.$route.params.category;
      this.getPostList();
    }
    this.getBanners();
  },
  methods: {
    getBanners() {
      fetchBanners().then((res) => {
        if (res.status == 200) {
          this.images = res.data.data;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    search() {
      this.getPostList();
    },
    getPostList() {
      let params = {
        inputMode: "title",
        searchWord: this.searchWord,
        category: this.category,
        sort: "latest",
        startAt: new Date(),
      };
      fetchPostList(params).then((res) => {
        if (res.data.status == 200) {
          this.postList = res.data.postList;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
